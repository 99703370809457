<template>
  <q-dialog v-model="formModal" persistent>
    <q-card flat class="justify-center" style="width: 1000px; max-width: 80vw">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> Editar Mailing {{ propsMailing.id }}</q-toolbar-title>
        <q-btn flat round dense icon="mdi-close" @click="$emit('close')" />
      </q-toolbar>
      <q-form>
        <q-card-section class="row q-col-gutter-md">
          <div class="col-6">
            <q-select
              v-model="params.campaignsSelected"
              :options="campaignsFiltered"
              label="Selecione a(s) Campanha(s)"
              outlined
              option-label="name"
              option-value="id"
              clear-icon="mdi-close"
              clearable
              use-chips
              multiple
              input-debounce="0"
              @filter="filterCampaigns"
              @input="handleCampaignSelection"
              @clear="handleCampaignClear"
            />
          </div>
          <div class="col-6">
            <q-select
              :disable="!isSingleCampaignSelected"
              v-model="channelsSelected"
              :options="filteredChannels"
              label="Escolha o Canal ou deixe em branco para todos"
              multiple
              use-chips
              outlined
              option-label="site_name"
              option-value="site_id"
              clear-icon="mdi-close"
              clearable
              use-input
              input-debounce="0"
            >
              <template v-slot:selected-item="scope">
                <q-chip
                  removable
                  size="0.8rem"
                  @remove="scope.removeAtIndex(scope.index)"
                  :tabindex="scope.tabindex"
                  color="primary"
                  text-color="white"
                  square
                  icon-remove="close"
                  input-class="text-caption"
                >
                  {{ scope.opt.site_name }}
                </q-chip>
              </template>
            </q-select>
          </div>
          <div class="col-12">
            <q-input
              ref="email"
              v-model="propsMailing.emails"
              label="Insira o email"
              lazy-rules
              outlined
              clearable
              clear-icon="mdi-close"
              :rules="params.campaignsSelected.length === 0 ? [isRequired] : null"
              error-message="Insira o email ou selecione uma campanha"
            />
          </div>
          <div class="col-12">
            <q-input
              outlined
              type="text"
              v-model="propsMailing.subject"
              label="Assunto"
              :rules="params.campaignsSelected.length === 0 ? null : [isRequired]"
            />
          </div>
          <div class="col-12">
            <q-input
              v-model="propsMailing.content"
              outlined
              type="textarea"
              lazy-rules
              rows="10"
              placeholder="Cole aqui o HTML"
            />
          </div>

          <div class="col-12 q-py-md row justify-end">
            <q-btn color="positive" label="Cancelar" no-caps outline unelevated class="col-2" @click="$emit('close')" />
            <q-btn color="primary" label="Salvar" no-caps unelevated class="col-2 q-mx-md" @click="saveMail" />
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import CampaignMixin from '../../../mixins/CampaignMixin';
export default {
  mixins: [CampaignMixin],
  name: 'MailingContentForm',

  props: {
    campaignList: [],
    propsMailing: {
      type: Object,
      required: true
    },
    formModal: {
      type: Boolean,
      default: false
    },
    channelsSelected: []
  },

  data() {
    return {
      campaignsFiltered: [],
      params: {
        campaignsSelected: [] // Array para armazenar múltiplas campanhas
      },
      channels: []
    };
  },

  computed: {
    isSingleCampaignSelected() {
      return this.params.campaignsSelected.length === 1;
    },
    filteredChannels() {
      if (this.isSingleCampaignSelected) {
        const selectedCampaignId = this.params.campaignsSelected[0].id;
        return this.channels.filter((item) => item.campaign_site_status <= 1 && item.campaign_id === selectedCampaignId);
      }
      return [];
    }
  },

  methods: {
    filterCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignsFiltered = this.campaignList;
        this.campaignsFiltered = this.campaignList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    handleCampaignSelection() {
      if (this.isSingleCampaignSelected) {
        const selectedCampaignId = this.params.campaignsSelected[0].id;
        this.getChannelsById(selectedCampaignId);
      } else {
        this.getChannelsById();
      }
    },

    handleCampaignClear() {
      this.params.campaignsSelected = []; // Limpa as campanhas selecionadas
      this.getChannelsById();
    },

    async saveMail() {
      this.onLoading(true);
      let lastCaracter = this.propsMailing.emails.charAt(this.propsMailing.emails.length - 1);
      if (lastCaracter == ';') this.propsMailing.emails = this.propsMailing.emails.slice(0, -1);

      // Converter os IDs das campanhas selecionadas em uma string separada por vírgulas
      const campaignIds = this.params.campaignsSelected.map((campaign) => campaign.id).join(',');

      try {
        const { status } = await this.$http.put(`/v2/redirect`, {
          app: `affiliate`,
          path: `/api/mailing/${this.propsMailing.id}`,
          params: {
            data: {
              subject: this.propsMailing.subject,
              content: this.propsMailing.content,
              emails: this.propsMailing.emails,
              campaign_id: campaignIds, // Passa os IDs como string separada por vírgulas
              sites: this.channelsSelected.map((v) => v.site_id)
            }
          }
        });

        if (status === 200 || status === 201) {
          this.successNotify('Mailing atualizado com sucesso!');
          this.$emit('close');
          this.$emit('setChannelsSelected', this.channelsSelected);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>